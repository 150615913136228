



.signin-body {
    height: 100vh;
    padding: 1rem;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-image: url(../../images/signin_bck.jpg);
    background-size: cover; 
    background-position: center; 
}
.my-form-signin{
    background-color: rgb(50,50,50);
    padding: 20px;
    width: 35%;
    opacity: 0.9;
}

.heading-signin{
    color: rgb(223,182,81);
    font-size: 26px;
}

.sub-heading-signin{
    color: rgb(255,255,255);
    margin-top: 20px;
    /* font-size: 20px; */
}

.my-form__button {
    
    background-color: var(--primary);
    color: var(--primary-text-color);
    white-space: nowrap;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2.5rem;
    line-height: 3.125rem;
    outline: none;
    /* font-size: 1.125rem; */
    letter-spacing: .025em;
    text-decoration: none;
    cursor: pointer;
    font-weight: 800;
    min-height: 3.125rem;
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 5px 10px rgba(0,0,0,.15);
    transition: all .3s ease;
    -webkit-transition: all .3s ease;
}

.my-form__button:hover {
    background-color: var(--second);
    color: var(--card-dark-shade);
}

.input__wrapper_signin {
    position: relative;
    padding: 0.9375rem 0 0;
    margin-bottom: 0.5rem;
}

.sigin-image{
    background-color: rgb(50,50,50);
    margin-right: 50px;
    margin-bottom: 130px;
    /* justify-content: start; */
    /* margin-bottom: 40px; */
    /* border-color: white; */
    /* background-image: url(../../images/signin-logo.jpg); */
}