/* SearchBar */
.table-searchbar {
    margin-right: 1rem;
    /* padding: 0 20px; */
}

@media (max-width: 1199px) {
    .table-searchbar {
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(255, 255, 255, 0.2);
        background: var(--card-dark-shade);
        z-index: 9999;
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
    }

    .table-searchbar-show {
        top: 60px;
        visibility: visible;
        opacity: 1;
    }
}

.table-searchform {
    width: 20%;
}

.table-searchform input {
    border: 0;
    font-size: 14px;
    color: var(--primary-text-color);
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 7px 38px 7px 38px;
    border-radius: 3px;
    transition: 0.3s;
    width: 100%;
    background-color: var(--card-dark-shade);
}

.table-searchform input:focus,
.table-searchform input:hover {
    outline: none;
    box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.table-searchform button {
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.table-searchform button i {
    color: var(--primary-text-color);
}

.table-searchinput {
    padding-left: 38px;
}
