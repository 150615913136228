/* SearchBar */

.search-bar{
    margin-right: 1rem;
    /* padding: 0 20px; */
}
@media (max-width: 1199px){
     .search-bar{
        position: fixed;
        top: 50px;
        left: 0;
        right: 0;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(255,255,255,0.2);;
        background: var(--card-dark-shade);
        z-index: 9999;
        transition: 0.3s;
        visibility: hidden;
        opacity: 0;
    }

    .search-bar-show {
        top: 60px;
        visibility: visible;
        opacity: 1;
    }
}
.search-form{
    width: 100%;
}

.search-form input{
    border: 0;
    font-size: 14px;
    color: var(--primary-text-color);
    border: 1px solid rgba(255,255,255,0.2);
    padding: 7px 38px 7px 8px;
    border-radius: 3px;
    transition: 0.3s;
    width: 100% ;
}

.search-form input:focus,
.search-form input:hover{
    outline: none;
    box-shadow: 0 0 10px 0 rgba(255,255,255,0.2);
    border: 1px solid rgba(255,255,255,0.2);
}
.search-form button {
    border: 0;
    padding: 0;
    margin-left: -30px;
    background: none;
}

.search-form button i{
    color: var(--primary-text-color);
}
.search-input{
    background-color: var(--card-dark-shade);
}